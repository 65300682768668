(function ($) {

  $(document).ready(function () {

    // **********************

    // check for ios
    if (/ipad|iphone|ipod/i.test(navigator.userAgent)) {
      $('html').addClass('ios');
    }

    // **********************

    $('body').on('click.scroll', 'a', function (e) {

      var file = $('body').attr('data-file');
      var href = $(this).attr('href');

      // href might not be set (e.g. in logofarm)
      if(href && href.indexOf('#') === 0 && href.length > 1) {
        e.preventDefault();
        $('body').scrollTo(href, 300);
      }
      else if(href && href.indexOf(file) === 0 && href.indexOf('#') > -1) {
        e.preventDefault();
        var link = '#' + href.split('#')[1];
        $('body').scrollTo(link, 300);
      }

    });

    // $.fn.imagesize = function () {

    //   var element = this;

    //   var title = element.attr('title');
    //   if (title.substr(0,5) === 'width') {
    //     var title_array = title.split('=');
    //     var width = title_array[1].trim();
    //     // var width = title.substr(6);
    //     if (width) {
    //       console.log('width', width);
    //       element.css('max-width', width);
    //       element.removeAttr('title');
    //     }
    //   }

    //   return this;

    // };

    $('.passage img').each(function(index) {
      var element = $(this);
      var title = element.attr('title');
      if (title.substr(0,5) === 'width') {
        var title_array = title.split('=');
        var width = $.trim(title_array[1]);
        if (width) {
          element.css('max-width', width);
          element.removeAttr('title');
        }
      }
    });

    $('.footer span[data-class^=foo]').each(function() {
      var element = $(this);
      var pclass = element.data('class');
      element.parent('p').addClass(pclass);
      element.remove();
    });

    // $('body').anchorhelper();

    $('body').ajaxForm();

    $('body').stop().animate({
      'opacity': 0}, 300, function () {
      $('body').css({
        'visibility': 'visible'
      }).animate({
        'opacity': 1
      }, 300);
    });

    // $('iframe').lazyLoad();

    // $('.preload').preload();

    $('#bottom').bottomize();

    // $('.jumbotron').jumbotronify({percent: 80});

    // $('.jumbotron').stagify({
    //   delay: 250,
    //   interval: 4000
    // });

    // $('a.popout').popOut();

    $('.modal-show').modal();

    $('.navbar ul').menuhelper();

    // $('.navbar-active-item').activenav();

    // $('body').flippify();

    // $('body').simplesliderfy();

  });

}(jQuery));
